import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import "./blog-post.css"
import SEO from "../components/seo"

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  let post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  const keywords = frontmatter.tags.join(", ")

  return (
    <Layout>
      <SEO title={`${frontmatter.title} | MCubed`} keywords={keywords} />
      <div className="blog-page" id="blog">
        <Link component="p" className="blog-return-link">
          Michael's Musings
        </Link>
        <hgroup>
          <h1 className="header">{frontmatter.title}</h1>
          <h3 className="date">{frontmatter.date}</h3>
        </hgroup>
        <div className="image-container">
          <Img className="featured-image" fluid={featuredImgFluid} />
        </div>

        <article
          className="content-container"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div className="author">
          Written by: Michael Ma, Owner of MCubed Design and Development
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(quality: 80, maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default Template
